<template>
    <div>

        <!-- Table -->

        <v-data-table
            v-model="table.selectedRows"
            :headers="table.headers"
            :items="filteredItems"
            :items-per-page.sync="perPage"
            :footer-props="{ itemsPerPageOptions: perPageOptions }"
            item-key="video.full"
            show-select
            dense
            :loading="loading"
            class="videoTable"
            :page.sync="page"
        >

            <template v-for="column in table.headers" v-slot:[`item.${column.value}`]="{ item }">
                {{ Array.from(item[column.value]).map(e => e.text).join(', ') }}
            </template>
        
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.video="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            {{ item.video.display }}
                        </div>
                    </template>
                    <span>{{ item.video.full }}</span> 
                </v-tooltip>
            </template>

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.thumbnail="{ item }">
                <v-tooltip bottom v-if="item.thumbnail">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <v-icon class="thumbnailIcon">fas fa-image</v-icon>
                        </div>
                    </template>
                    <span>{{ item.thumbnail }}</span> 
                </v-tooltip>
            </template>

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.dropdown="{ item }">

                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" small>
                            {{ $t(`tagSystem.tab.video.dropdown.manage`) }} <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item v-for="dropdownItem in item.dropdown" :key="dropdownItem.id" @click="dropdownItem.action">
                            <v-list-item-title>{{ dropdownItem.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

            </template>
        
        </v-data-table>

        <!-- Dialogs -->

        <v-dialog v-model="modal.edit.display" max-width="100%" fullscreen hide-overlay>
            <v-card flat class="dialog-card">
                <v-app-bar color="primary" dark>
                    <v-toolbar-title>
                        {{ $t(`tagSystem.tab.video.modal.edit.title`) }} - {{ modal.edit.form.video }}
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn icon @click="modal.edit.display = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
                <v-card-text class="scrollable-content">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="6">
                                  <!-- Video location -->
                                  <v-text-field
                                        v-model="modal.edit.form.video"
                                        :label="$t(`tagSystem.tab.video.modal.edit.form.video`)"
                                        readonly
                                        disabled
                                    ></v-text-field>

                                    <v-text-field
                                            v-model="modal.edit.form.thumbnail.title"
                                            :label="$t(`tagSystem.tab.video.modal.edit.form.thumbnail`)">
                                    </v-text-field>

                                    <!-- Thumbnail -->
                                    <div v-if="modal.edit.form.thumbnail.display">
                                        <h4>{{ $t(`tagSystem.tab.video.modal.edit.header.thumbnail`) }}</h4>
                                        <div class="thumbnailContainer">
                                            <img v-if="showThumbnail" :src="modal.edit.form.thumbnail.file" :alt="modal.edit.form.thumbnail.title">
                                            <div class="buttons" :class="showThumbnail ? '' : 'noThumbnail'">
                                                <v-btn @click="modal.edit.form.thumbnail.delete = true" v-if="showThumbnail">{{ $t(`tagSystem.tab.video.modal.edit.form.delete`) }}</v-btn>
                                                <v-btn @click="$refs.fileInput.click()">{{ $t(`tagSystem.tab.video.modal.edit.form.upload`) }}</v-btn>

                                                <!-- Hidden file input -->
                                                <input type="file" ref="fileInput" style="display: none" @change="handleFileSelect">
                                            </div>
                                        </div>


                                    </div>

                                    <v-row>
                                        <v-col v-for="(thumbnail, index) in modal.generatedThumbnails" :key="index" cols="3">
                                            <v-img :src="thumbnail.src" @click="selectThumbnail(thumbnail)" :class="thumbnail.src === modal.selectedThumbnail ? 'selectedThumbnail' : ''" width="100%"></v-img>
                                        </v-col>
                                    </v-row>
                            </v-col>

                            <v-col cols="12" md="6">
                                <div class="modalBody">
                                    <!-- Video tags -->
                                    <h4>{{ $t(`tagSystem.tab.video.modal.edit.header.tags`) }}</h4>
                                    
                                    <v-combobox 
                                        v-for="category in categoryTags" 
                                        v-model="modal.edit.form.tags[category.id]"
                                        :key="category.id"
                                        :label="capitalize(category.category)"
                                        :items="category.tags.map(t => ({text: t.tag, value: t.id}))"
                                        item-text="text"
                                        item-value="value"
                                        multiple
                                        clearable                        
                                    ></v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="modal.edit.display = false">
                        {{ $t("tagSystem.tab.video.modal.edit.cancel") }}
                    </v-btn>
                    <v-btn @click="submitEdit" color="primary">
                        {{ $t(`tagSystem.tab.video.modal.edit.submit`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modal.delete.display" width="500">
            <v-card>
                <v-card-title>
                    {{ $t(`tagSystem.tab.video.modal.delete.title`) }}
                </v-card-title>
                
                <v-card-text>
                    {{ $t(`tagSystem.tab.video.modal.delete.description`) }}
                </v-card-text>
                
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="modal.delete.display = false">
                        {{ $t("tagSystem.tab.video.modal.delete.cancel") }}
                    </v-btn>
                    <v-btn @click="submitDelete" color="danger">
                        {{ $t(`tagSystem.tab.video.modal.delete.submit`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        

        <ModalTagAddToItem 
            :title="$t(`tagSystem.modal.tags.add.title`)" 
            :categoryTags="categoryTags"
            :selectedIds="table.selectedRows.map(e => e.video.id)"
            :apiUrl="`${apiServer}/videos/*_id_*/addTags`"
            ref="modalTagAddToItem"
        />

        <ModalTagDeleteFromItem 
            :title="$t(`tagSystem.modal.tags.delete.title`)" 
            :selectedIds="table.selectedRows.map(e => e.video.id)"
            :apiUrl="`${apiServer}/videos/*_id_*/removeTags`"
            ref="modalTagDeleteFromItem"
            :itemStr="$t(`tagSystem.tab.video.item`)"
            :tagOptions="selectedVideosTags"
        />
        
        <ModalItemDelete 
            :title="$t(`tagSystem.modal.items.delete.title.video`)" 
            :selectedIds="table.selectedRows.map(e => e.video.id)"
            :apiUrl="`${apiServer}/videos/*_id_*`"
            slug="video"
            ref="modalItemDelete"
        />

        <ModalItemAddAndUpload 
            :title="$t(`tagSystem.modal.items.add.title.video`)" 
            :apiUrl="`${videoApiServer}/upload-video`"
            :locationOptions="locationOptions"
            locationPrefix="video"
            acceptFormat=".mov,.mp4,.webm,.ogm,.ogv"
            slug="video"
            ref="modalItemAdd"
            :tus="true"
        />

        <ModalTagAddAuto 
            :apiUrl="`${apiServer}/videos/*_id_*/addTags`"
            :videos="videos"
            :tags="tags"
            ref="modalTagAddAuto"
        />

    </div>
</template>

<script>
import axios from "axios";
import { capitalize } from '@/utils/utils.js';
import { emitFeedback, getTagsByCategory, filterRows, displayName, sortOnTags, getVideoLocationOptions, sortOnDisplay } from '@/utils/tagSystem';
import ModalTagAddToItem from '@/components/tags/modals/TagAddToItem.vue';
import ModalTagDeleteFromItem from '@/components/tags/modals/TagDeleteFromItem.vue';
import ModalTagAddAuto from '@/components/tags/modals/TagAddAuto.vue';
import ModalItemDelete from '@/components/tags/modals/ItemDelete.vue';
import ModalItemAddAndUpload from '@/components/tags/modals/ItemAddAndUpload.vue';
import { ILLI_API_SERVER_URL, THUMBNAIL_URL, MR_ILLI_VIDEO_API_SERVER_URL, ILLI_STREAM_STATIC_URL } from '../../config'
import { EventBus } from '@/utils/eventBus';
import path from 'path';
import tagTableMixin from '@/mixins/tagTableMixin';

export default {
    name: "VideoTable",
    components: {
        ModalTagAddToItem,
        ModalTagDeleteFromItem,
        ModalItemDelete,
        ModalItemAddAndUpload,
        ModalTagAddAuto
    },
    mixins: [tagTableMixin],
    props: {
        searchQuery: {
            type: String,
            default: ''
        },
        filters: {
            type: Array,
            default: () => [],
        },
    },  
    computed: {
        filteredItems() {
            return filterRows(this.table.rows, this.filters, this.searchQuery);
        },
        apiServer() {
            return ILLI_API_SERVER_URL;
        },
        videoApiServer() {
            return MR_ILLI_VIDEO_API_SERVER_URL;
        },
        showThumbnail(){
            return (this.modal.edit.form.thumbnail.file && !this.modal.edit.form.thumbnail.delete)
        },
        selectedVideosTags(){
            const videoIds = this.table.selectedRows.map(e => e.video.id);

            return this.videos
                .filter(e => videoIds.includes(e.id))
                .map(e => e.tags)
                .reduce((all, crr) => {
                    crr.forEach(obj => {
                        if (!all.some(allObj => allObj.id === obj.id)) {
                            all.push(obj);
                        }
                    });
                    return all;
                }, [])
                .sort((a, b) => a.tag.localeCompare(b.tag));
        }
    },
    watch: {
        'table.selectedRows': {
            handler(value) {
                this.$emit("selectedRows", {amount:  value.length});  
            },
        }
    },
    created(){

        Promise.all([
            this.getVideos(),
            this.getCategoryTags(),
            this.getThumbnails(),
        ])
        .then(([videoData, categoryTagData, thumbnailData]) => {

            // Set data
            this.videos = videoData;
            this.categoryTags = categoryTagData.map(c => ({...c, tags: c.tags.sort((a, b) => a.tag.localeCompare(b.tag))}));
            this.tags = this.categoryTags.flatMap(e => e.tags);
            this.locationOptions = getVideoLocationOptions(this.videos);

            this.thumbnails = thumbnailData;

            // Create table
            this.setHeaders();
            this.setRows();

            // Emit filter options
            this.getFilterOptions();

        })
        .catch((err) => {
            console.error(err);
        })

    },
    data(){
        return {
            table: {
                headers: [],
                rows: [],
                selectedRows: [],
            },

            modal: {
                selectedThumbnail: null, // new property for selected thumbnail
                generatedThumbnails: [],
                edit: {
                    videoId: null,
                    display: false,
                    form: {
                        thumbnail: {
                            file: '',
                            title: '',
                            display: false,
                            uploadFile: null,
                            delete: false,
                        },
                        video: '',
                        tags: [],
                    }
                },
                delete: {
                    videoId: null,
                    display: false,
                }
            },

            videos: [],
            categoryTags: [],
            tags: [],
            thumbnails: [],
            locationOptions: []
        }
    },
    methods: {
        // Get data from API

        selectThumbnail(thumbnail) {
            this.modal.selectedThumbnail = thumbnail.src; // update selected thumbnail
            this.modal.edit.form.thumbnail.display = true;
            this.modal.edit.form.thumbnail.delete = false;
            this.modal.edit.form.thumbnail.file = thumbnail.src;

            // Fetch the image, create a blob and create a blob URL
            axios.get(thumbnail.src, { 
                responseType: 'blob',
                withCredentials: false 
            })
            .then(response => {
                let blob = new Blob([response.data], { type: 'image/jpeg' });
                let filename = thumbnail.src.split('/').pop();
                let file = new File([blob], filename, { type: 'image/jpeg' });
                this.modal.edit.form.thumbnail.uploadFile = file;
            });
        },
        handleFileSelect(event){
            const file = event.target.files[0];
            this.modal.edit.form.thumbnail.delete = false;
            this.modal.edit.form.thumbnail.uploadFile = file;
            this.modal.edit.form.thumbnail.file = URL.createObjectURL(file);
        },
        getVideos(){
            return new Promise((resolve, reject) => {
                axios.get(`${ILLI_API_SERVER_URL}/videos`)
                    .then(response => {
                        resolve(response.data.videos);
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        getCategoryTags(){
            return new Promise((resolve, reject) => {
                axios.get(`${ILLI_API_SERVER_URL}/categoryTags`)
                    .then(response => {
                        resolve(response.data.categoryTags);
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        getThumbnails(){
            return new Promise((resolve) => {
                axios.get(`${ILLI_API_SERVER_URL}/videoThumbnails`)
                    .then(response => {
                        resolve(
                            response.data.thumbnails.map((file) => {
                                const id = file.split(".")[0];
                                return { file, id };
                            })
                        );
                    })
                    .catch(error => {
                        console.error({error});
                        resolve([]);
                    });
            });
        },

        // Create table

        setHeaders(){
            const headerVideo = {text: 'Video', value: 'video', sort: sortOnDisplay};
            const headerThumbnail = {text: '', value: 'thumbnail', filterable: false, sortable: false};
            const headerDropdown = {text: '', value: 'dropdown', filterable: false, sortable: false};
            this.table.headers = [headerVideo, headerThumbnail]
                .concat(this.categoryTags.map((e) => ({text: capitalize(e.category), value: e.category, sort: sortOnTags})))
                .concat([headerDropdown]);
        },

        setRows(){
            this.table.rows = this.videos.map((v) => {
                const tagsByCategory = getTagsByCategory(this.categoryTags, v, 'category', true);
                const hasThumbnail = this.thumbnails.some(t => t.id == v.cloudflareId);
                const thumbnailTitle = (hasThumbnail) ? v.title : null;

                return {video: {id: v.id, full: v.video, display: displayName(v.video)}, thumbnail: thumbnailTitle, ...tagsByCategory, dropdown: this.getDropdown(v.id)};
            });
        },

        getDropdown(id){
            return [
                {id: 'edit', text: this.$t(`tagSystem.tab.video.dropdown.edit`), action: () => this.openEditModal(id)},
                {id: 'delete', text: this.$t(`tagSystem.tab.video.dropdown.delete`), action: () => this.openDeleteModal(id)},
            ];
        },
        // Modal actions

        openEditModal(id){
            this.modal.edit.videoId = id;
            this.modal.edit.form.thumbnail.delete = false;

            // Get data
            const video = this.videos.find(v => v.id == id);

            const thumbnail = this.thumbnails.find(t => t.id == video.cloudflareId);
            const tagsByCategory = getTagsByCategory(this.categoryTags, video, 'id', true);
            
            // Set modal data
            this.modal.edit.form.video = video.video;
            this.modal.edit.form.thumbnail.file = (thumbnail?.file) ? `${THUMBNAIL_URL.video}${thumbnail.file}?${new Date().getTime()}` : null;
            this.modal.edit.form.thumbnail.title = video.title;
            this.modal.edit.form.thumbnail.display = true; // Enable thumbnails for every video
            this.modal.edit.form.tags = tagsByCategory;

            this.modal.edit.form.thumbnail.pathOnly = path.dirname(video.video);
            this.modal.edit.form.thumbnail.filenameOnly = path.basename(video.video, path.extname(video.video));

            const thumb_base_url = `${ILLI_STREAM_STATIC_URL}/generated_thumbnails/`;

            // make list of thumbnails based on base_url and pathOnly + filenameOnly + _1to9 + .jpg
            this.modal.generatedThumbnails = Array.from({length: 15}, (_, i) => i + 1).map(i => ({
                src: `${thumb_base_url}${this.modal.edit.form.thumbnail.pathOnly}/${this.modal.edit.form.thumbnail.filenameOnly}-${i <= 5 ? 'thumbnail-intro' : 'thumbnail'}-${i <= 5 ? i : i - 5}.jpg`
            }));
            this.modal.edit.display = true;
        },

        submitEdit(){
            console.log('edit video');

            const video = this.videos.find(v => v.id == this.modal.edit.videoId);
            const cloudflareId = video.cloudflareId;

            const body = {
                tags: Object.values(this.modal.edit.form.tags).flatMap(t => t).map(t => t.value),
                title: this.modal.edit.form.thumbnail.title ?? '',
            };

            const promises = [
                axios.patch(`${ILLI_API_SERVER_URL}/videos/${this.modal.edit.videoId}`, body)
            ];

            if (this.modal.edit.form.thumbnail.uploadFile){
                // Gather data
                const formData = new FormData();
                formData.append('file', this.modal.edit.form.thumbnail.uploadFile);

                // Add promise
                promises.push(axios.patch(`${ILLI_API_SERVER_URL}/videos/${cloudflareId}/thumbnail`, formData));
            }

            if (this.modal.edit.form.thumbnail.delete){
                // Add promise
                promises.unshift(axios.delete(`${ILLI_API_SERVER_URL}/videos/${cloudflareId}/thumbnail`));
            }

            Promise.all(promises)
                .then((response) => {
                    console.log({response});
                    return emitFeedback(this, 'Video succesvol aangepast', 'success'); // todo translate
                })
                .catch((err) => {
                    console.error({err});
                    return emitFeedback(this, 'Er is iets misgegaan', 'error'); // todo translate 
                })
                .finally(() => {
                    this.modal.edit.display = false;
                    EventBus.$emit('refresh');
                });

        },

        openDeleteModal(id){
            this.modal.delete.videoId = id;

            // Open modal
            this.modal.delete.display = true;
        },



        submitDelete(){
            console.log('delete video', this.modal.delete.videoId);

            axios.delete(`${ILLI_API_SERVER_URL}/videos/${this.modal.delete.videoId}`)
                .then((response) => {
                    console.log({response});
                    return emitFeedback(this, 'Video succesvol verwijderd', 'success'); // todo translate
                })
                .catch((error) => {
                    console.error({error});
                    return emitFeedback(this, 'Er is iets misgegaan', 'error'); // todo translate 
                })
                .finally(() => {
                    this.modal.delete.display = false;
                    EventBus.$emit('refresh');
                });
        },

        openAddTagAutoModal(){
            this.$refs.modalTagAddAuto.openModal();
        },

        openAddTagToItemModal(){
            this.$refs.modalTagAddToItem.openModal();
        },

        openDeleteTagFromItemModal(){
            console.warn(
                this.selectedVideosTags
            );

            this.$refs.modalTagDeleteFromItem.openModal();
        },

        openDeleteItemModal(){
            this.$refs.modalItemDelete.openModal();
        },

        openAddItemModal(){
            this.$refs.modalItemAdd.openModal();
        },

        // Functions

        getFilterOptions(){
            const filters = this.categoryTags.map((e) => ({
                column: {
                    name: e.category,
                    value: e.category,
                },
                options: e.tags.map((t) => ({text: t.tag, value: t.id})),
                selected: e.tags.map((t) => t.id),
            }));

            this.$emit("filterOptions", filters)
        },

        getSelectedRows(){
            return this.table.selectedRows.length || 0;
        },

        // Include capitalize function here to use in template
        capitalize,
    },
}
</script>

<style lang="scss">

.videoTable{

    th{
        text-wrap: nowrap;
    }
    
    .thumbnailIcon{
        font-size: 15px!important;
    }

    td:last-child{
        position: sticky;
        right: 0;
    }

}

</style>

<style lang="scss" scoped>

.thumbnailContainer{
    position: relative;

    margin-bottom: 20px;

    .buttons{
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        gap: 10px;
        padding: 10px;

        &.noThumbnail{
            padding: 0;
            position: relative
        }
    }

    img {
        width: 100%;
        display: block;
    } 
}
.dialog-card {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.v-app-bar {
    position: sticky;
    top: 0;
    z-index: 2;
}
.scrollable-content {
    overflow-y: auto;
    height: calc(100vh - 64px); /* 64px is the height of the v-app-bar */
}

.selectedThumbnail {
    border: 2px solid #000; // add border to selected thumbnail
}


</style>