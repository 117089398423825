<template>
    <div>

        <v-dialog v-model="display" width="500">
            <v-card>
                <v-card-title>{{ $t("tagSystem.modal.tags.auto.title") }}</v-card-title>
                
                <v-card-text>{{ $t("tagSystem.modal.tags.auto.description") }} </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="display = false">
                        {{ $t("tagSystem.modal.tags.auto.cancel") }}
                    </v-btn>
                    <v-btn @click="save" color="primary" :disabled="disabled">
                        {{ $t(`tagSystem.modal.tags.auto.save`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="showMessage" :timeout="3000" color="warning">
            <strong>{{ $t(`tagSystem.modal.tags.auto.noTags`) }}</strong>
        </v-snackbar>

        <v-snackbar>

        </v-snackbar>

    </div>
</template>

<script>
import axios from 'axios'
import { capitalize, delay} from '@/utils/utils.js';
import { EventBus } from '@/utils/eventBus';

export default {

    props: {
        videos: {
            type: Array,
            default: () => []
        },
        tags: {
            type: Array,
            default: () => []
        },
        apiUrl: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            display: false,
            disabled: false,
            showMessage: false
        }
    },
    methods: {

        displayName(folder){
            const regex = /(?<=\/[0-9]{2}-)[^/]*/
            let displayName = (folder.match(regex)) ? folder.match(regex)[0] : folder;
            displayName = displayName.replace(/^\/YT-\d{1,3}-/, '[YT] ')
            displayName = displayName.replaceAll('/', '');
            displayName = displayName.replaceAll('_', ' ');
            displayName = displayName.replaceAll('-', ' ');
            return displayName;
        },

        getSuggestions(amount = Number.MAX_VALUE){

            return this.videos.slice(0, amount).map((video) => {
                const videoPath = video.video.split('/');
                return {
                    id: video.id,
                    tags: [
                        this.tags.find(t => t.tag == this.displayName(`/${videoPath[0]}`) && t.categoryId == 1)?.id, // hoofd
                        this.tags.find(t => t.tag == this.displayName(`/${videoPath[1]}`) && t.categoryId == 2)?.id, // sub
                    ]
                    .filter(sugId => !video.tags.some(t => t.id == sugId)) // remove already existing tags
                    .filter(e => e) // remove undefined
                }
            })
            .filter(video => video.tags.length > 0);
        
        },

        splitArray(arr, groupSize){
            return arr.reduce((all,one,i) => {
                const ch = Math.floor(i/groupSize); 
                all[ch] = [].concat((all[ch]||[]),one); 
                return all
            }, [])
        },

        openModal(){
            this.display = true;
        },

        save(){
            this.disabled = true;

            const suggestions = this.getSuggestions();

            console.log({suggestions});

            const suggestionGroups = this.splitArray(suggestions, 20);

            console.log({suggestionGroups});

            if (suggestionGroups.length == 0){
                this.disabled = false;  
                this.showMessage = true;
                this.display = false;
                return;
            }

            // Add suggestions to videos in groups of 20
            for (let group = 0, p = Promise.resolve(); group < suggestionGroups.length; group++) {
                p = p.then(() => console.log({group}))
                    .then(() => this.addSuggestionsToVideos(suggestionGroups[group]))
                    .then(() => {
                        if (group == suggestionGroups.length - 1){
                            console.log('done')
                            EventBus.$emit('refresh');
                        } 
                    })
                    .then(() => delay(1000))
            }

        },

        addSuggestionsToVideos(suggestions){
            return Promise.all(
                suggestions.map(suggestion => {
                    const apiURL = this.apiUrl.replace('*_id_*', suggestion.id);
                    return axios.patch(apiURL, {
                        tags: suggestion.tags
                    })
                })
            )
        },

        capitalize
    }

}
</script>

<style scoped>



</style>
