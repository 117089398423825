import { render, staticRenderFns } from "./ImageTable.vue?vue&type=template&id=24622172&scoped=true&"
import script from "./ImageTable.vue?vue&type=script&lang=js&"
export * from "./ImageTable.vue?vue&type=script&lang=js&"
import style0 from "./ImageTable.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ImageTable.vue?vue&type=style&index=1&id=24622172&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24622172",
  null
  
)

export default component.exports