<template>
    <div>

        <!-- Table -->

        <v-data-table
            v-model="table.selectedRows"
            :headers="table.headers"
            :items="filteredItems"
            :items-per-page.sync="perPage"
            :footer-props="{ itemsPerPageOptions: perPageOptions }"
            item-key="key"
            show-select
            dense
            :loading="loading"
            class="tagTable"
            :page.sync="page"
        >
        
            <template v-for="column in table.headers" v-slot:[`item.${column.value}`]="{ item }">
                {{ Array.from(item[column.value]).map(e => e.text).join(', ') }}
            </template>

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.videoCount="{ item }">
                <a v-if="item.videoCount[0].value > 0" href="#" @click="openVideosWithTag(item.id[0].value)">
                    {{ item.videoCount[0].text }}
                </a>
                <span v-else>{{ item.videoCount[0].text }}</span>
            </template>
            
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.songCount="{ item }">
                <a v-if="item.songCount[0].value > 0" href="#" @click="openSongsWithTag(item.id[0].value)">
                    {{ item.songCount[0].text }}
                </a>
                <span v-else>{{ item.songCount[0].text }}</span>
            </template>
            
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.imageCount="{ item }">
                <a v-if="item.imageCount[0].value > 0" href="#" @click="openImagesWithTag(item.id[0].value)">
                    {{ item.imageCount[0].text }}
                </a>
                <span v-else>{{ item.imageCount[0].text }}</span>
            </template>

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.dropdown="{ item }">

                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" small>
                            {{ $t(`tagSystem.tab.tag.dropdown.manage`) }} <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item v-for="dropdownItem in item.dropdown" :key="dropdownItem.id" @click="dropdownItem.action">
                            <v-list-item-title>{{ dropdownItem.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

            </template>
            
        </v-data-table>

        <!-- Dialogs -->
        
        <ModalTagAddOrEdit 
            slug="add"
            :categories="categories"
            :apiUrl="`${apiServer}/tags/`"
            ref="modalTagAdd"
        />

        <ModalTagAddOrEdit 
            slug="edit"
            :categories="categories"
            :selected="selected"
            :apiUrl="`${apiServer}/tags/*_id_*`"
            ref="modalTagEdit"
        />

        <ModalItemDelete             
            :title="$t(`tagSystem.modal.items.delete.title.tag`)" 
            :selectedIds="selected.ids"
            :apiUrl="`${apiServer}/tags/*_id_*`"
            slug="tag"
            ref="modalTagDelete"
        />

    </div>
</template>

<script>
import axios from "axios";
import { capitalize } from '@/utils/utils';
import { filterRows, sortOnText, sortOnIntValue } from '@/utils/tagSystem'; 
import { ILLI_API_SERVER_URL } from "../../config";
import ModalTagAddOrEdit from '@/components/tags/modals/TagAddOrEdit.vue';
import ModalItemDelete from '@/components/tags/modals/ItemDelete.vue';
import { EventBus } from '@/utils/eventBus';
import tagTableMixin from '@/mixins/tagTableMixin';

export default {
    name: "TagTable",
    components: {
        ModalTagAddOrEdit,
        ModalItemDelete
    },
    mixins: [tagTableMixin],
    props: {
        searchQuery: {
            type: String,
            default: ''
        },
        filters: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        filteredItems() {
            return filterRows(this.table.rows, this.filters, this.searchQuery);
        },
        apiServer() {
            return ILLI_API_SERVER_URL;
        }
    },
    watch: {
        'table.selectedRows': {
            handler(value) {
                this.$emit("selectedRows", {amount:  value.length});  
            },
        },
    },
    created(){

        Promise.all([
            this.getTags(),
            this.getCategoryTags(),
        ])
        .then(([tagData, categoryTagData]) => {

            // Set data
            this.tags = tagData;
            this.categories = categoryTagData.map(c => ({id: c.id, category: c.category}));

            // Create table
            this.setHeaders();
            this.setRows();

            // Emit filter options
            this.getFilterOptions();

        })
        .catch((err) => {
            console.error(err);
        })

    },
    data(){
        return {
            table: {
                headers: [],
                rows: [],
                selectedRows: [],
            },

            selected: {
                ids: [],
                tag: '',
                category: '',
            },

            tags: [],
            categories: [],
            locationOptions: [],
        }
    },
    methods: {

        // Get data from API

        getTags(){
            return new Promise((resolve, reject) => {
                axios.get(`${ILLI_API_SERVER_URL}/tagCategories`)
                    .then(response => {
                        resolve(response.data.tags);
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        getCategoryTags(){
            return new Promise((resolve, reject) => {
                axios.get(`${ILLI_API_SERVER_URL}/categoryTags`)
                    .then(response => {
                        resolve(response.data.categoryTags);
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    });
            });
        },

        // Create table

        setHeaders(){
            this.table.headers = [
                {text: 'Id', value: 'id', sort: sortOnIntValue},
                {text: 'Tag', value: 'tag', sort: sortOnText},
                {text: 'Categorie', value: 'category', sort: sortOnText},
                {text: '# video\'s', value: 'videoCount', sort: sortOnIntValue},
                {text: '# liedjes', value: 'songCount', sort: sortOnIntValue},
                {text: '# afbeeldingen', value: 'imageCount', sort: sortOnIntValue},
                {text: '', value: 'dropdown', filterable: false, sortable: false},
            ];
        },

        setRows(){
            this.table.rows = this.tags.map((i) => {
                return {
                    key: i.id,
                    id: [
                        {
                            value: i.id, 
                            text: i.id,
                        }
                    ],
                    tag: [
                        {
                            value: i.id, 
                            text: i.tag,
                        }
                    ],
                    category: [
                        {
                            value: i.category?.id, 
                            text: i.category?.category,
                        }
                    ],
                    videoCount: [
                        {
                            value: i.video_count, 
                            text: i.video_count,
                        }
                    ],
                    songCount: [
                        {
                            value: i.song_count, 
                            text: i.song_count,
                        }
                    ],
                    imageCount: [
                        {
                            value: i.image_count, 
                            text: i.image_count,
                        }
                    ],
                    dropdown: this.getDropdown(i.id)
                };
            });
        },

        getDropdown(id){
            return [
                {id: 'edit', text: this.$t(`tagSystem.tab.tag.dropdown.edit`), action: () => this.openEditTagModalDropdown(id)},
                {id: 'delete', text: this.$t(`tagSystem.tab.tag.dropdown.delete`), action: () => this.openDeleteTagModalDropdown(id)},
            ];
        },

        openVideosWithTag(id){
            this.$router.push({ name: 'tagSystem', query: { tab: 'video', tag: id } });
            EventBus.$emit('refresh');
        },

        openSongsWithTag(id){
            this.$router.push({ name: 'tagSystem', query: { tab: 'song', tag: id } });
            EventBus.$emit('refresh');
        },

        openImagesWithTag(id){
            this.$router.push({ name: 'tagSystem', query: { tab: 'image', tag: id } });
            EventBus.$emit('refresh');
        },

        // Modals

        openAddItemModal(){
            this.$refs.modalTagAdd.openModal();
        },

        openEditTagModal(){
            this.selected.ids = Object.values(this.table.selectedRows).map(e => e.id[0].value)
            this.$refs.modalTagEdit.openModal();
        },

        openDeleteTagModal(){
            this.selected.ids = Object.values(this.table.selectedRows).map(e => e.id[0].value)
            this.$refs.modalTagDelete.openModal();
        },

        openEditTagModalDropdown(id){
            const tag = this.tags.find(t => t.id === id);

            this.selected.ids = [id];
            this.selected.tag = tag.tag;
            this.selected.category = tag.category?.id;

            this.$refs.modalTagEdit.openModal();
        },

        openDeleteTagModalDropdown(id){
            const tag = this.tags.find(t => t.id === id);
            
            this.selected.ids = [id];
            this.selected.tag = tag.tag;
            this.selected.category = tag.category?.id;
            
            this.$refs.modalTagDelete.openModal();
        },

        // Functions

        getFilterOptions(){

            const columns = [
                {name: 'Id', value: 'id', options: {text: ['id'], value: ['id']}},
                {name: 'Tag', value: 'tag', options: {text: ['tag'], value: ['id']}},
                {name: 'Categorie', value: 'category', options: {text: ['category','category'], value: ['category','id']}},
                {name: '# video\'s', value: 'videoCount', options: {text: ['video_count'], value: ['video_count']}}, 
                {name: '# liedjes', value: 'songCount', options: {text: ['song_count'], value: ['song_count']}}, 
                {name: '# afbeeldingen', value: 'imageCount', options: {text: ['image_count'], value: ['image_count']}}, 
            ];

            const filters = columns.map((c) => {

                const options = Array.from(
                    new Set(
                        this.tags.map((t) => JSON.stringify({
                            text: c.options.text.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : null, t),
                            value: c.options.value.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : null, t)
                        }))
                    )
                )
                .map((option) => JSON.parse(option))
                .filter(e => e.value != null)
                .sort((a, b) => `${a.text}`.localeCompare(`${b.text}`));

                return {
                    column: {
                        name: c.name,
                        value: c.value
                    },
                    options: options,
                    selected: options.map((o) => o.value),
                }
            });

            this.$emit("filterOptions", filters)
        },

        getSelectedRows(){
            return this.table.selectedRows.length || 0;
        },

        // Include capitalize function here to use in template
        capitalize,
    },
}
</script>

<style lang="scss">

.tagTable{

    th{
        text-wrap: nowrap;
    }

    td:last-child{
        text-align: right!important;
        position: sticky;
        right: 0;
    }
    
}

</style>

<style lang="scss" scoped>

</style>