<template>
    <div>

        <!-- Table -->

        <v-data-table
            v-model="table.selectedRows"
            :headers="table.headers"
            :items="filteredItems"
            :items-per-page.sync="perPage"
            :footer-props="{ itemsPerPageOptions: perPageOptions }"
            item-key="video.full"
            show-select
            dense
            :loading="loading"
            class="folderTable"
            :page.sync="page"
        >

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.thumbnail="{ item }">
                <v-tooltip bottom v-if="item.thumbnail">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <v-icon class="thumbnailIcon">fas fa-image</v-icon>
                        </div>
                    </template>
                    <span>{{ item.thumbnail.id }}.{{ item.thumbnail.extension }}</span> 
                </v-tooltip>
            </template>

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.button="{ item }">
                <v-btn @click="openEditModal(item.button.path)" small>{{ $t('tagSystem.tab.folder.editButton') }}</v-btn>
            </template>
        
        </v-data-table>

        <!-- Dialogs -->

        <v-dialog v-model="modal.edit.display" width="500">
            <v-card>
                <v-card-title>
                    {{ $t(`tagSystem.tab.folder.modal.edit.title`) }}
                </v-card-title>
                
                <div class="modalBody">

                    <!-- Thumbnail -->
                    <div v-if="modal.edit.form.thumbnail.display">
                        <h4>{{ $t(`tagSystem.tab.folder.modal.edit.header.thumbnail`) }}</h4>

                        <div class="thumbnailContainer">
                            <img v-if="showThumbnail" :src="modal.edit.form.thumbnail.file" :alt="modal.edit.form.thumbnail.title">
                            <div class="buttons" :class="showThumbnail ? '' : 'noThumbnail'">
                                <v-btn @click="deleteThumbnail" v-if="showThumbnail">{{ $t(`tagSystem.tab.folder.modal.edit.form.delete`) }}</v-btn>
                                <v-btn @click="$refs.fileInput.click()">{{ $t(`tagSystem.tab.folder.modal.edit.form.upload`) }}</v-btn>

                                <!-- Hidden file input -->
                                <input type="file" ref="fileInput" style="display: none" @change="handleFileSelect">
                            </div>
                        </div>
                    </div>
      
                </div>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="modal.edit.display = false">
                        {{ $t("tagSystem.tab.folder.modal.edit.cancel") }}
                    </v-btn>
                    <v-btn @click="submitEdit" color="primary">
                        {{ $t(`tagSystem.tab.folder.modal.edit.submit`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from "axios";
import { capitalize } from '@/utils/utils.js';
import { emitFeedback, filterRows } from '@/utils/tagSystem';
import { ILLI_API_SERVER_URL, THUMBNAIL_URL  } from '../../config'
import { EventBus } from '@/utils/eventBus';
import tagTableMixin from '@/mixins/tagTableMixin';

export default {
    name: "FolderTable",
    mixins: [tagTableMixin],
    props: {
        searchQuery: {
            type: String,
            default: ''
        },
        filters: {
            type: Array,
            default: () => [],
        },
    },  
    computed: {
        filteredItems() {
            return filterRows(this.table.rows, this.filters, this.searchQuery);
        },
        apiServer() {
            return ILLI_API_SERVER_URL;
        },
        showThumbnail(){
            return (this.modal.edit.form.thumbnail.file && !this.modal.edit.form.thumbnail.delete)
        }
    },
    watch: {
        'table.selectedRows': {
            handler(value) {
                this.$emit("selectedRows", {amount:  value.length});  
            },
        }
    },
    created(){

        Promise.all([
            this.getFolders(),
            this.getThumbnails(),
        ])
        .then(([folderData, thumbnailData]) => {

            // Set data
            this.folders = folderData.map((f) => ({
                path: f,
                thumbnail: thumbnailData.find(t => t.folder == f) || null,
            }));

            // Create table
            this.setHeaders();
            this.setRows();

            // Emit filter options
            this.getFilterOptions();

        })
        .catch((err) => {
            console.error(err);
        })

    },
    data(){
        return {
            table: {
                headers: [],
                rows: [],
                selectedRows: [],
            },

            modal: {
                edit: {
                    path: null,
                    display: false,
                    form: {
                        thumbnail: {
                            file: '',
                            title: '',
                            display: false,
                            uploadFile: null,
                            delete: false,
                        },
                    }
                }
            },

            folders: [],
        }
    },
    methods: {
        // Get data from API

        getFolders(){
            return new Promise((resolve, reject) => {
                axios.get(`${ILLI_API_SERVER_URL}/videos`)
                    .then(response => {

                        console.warn(response.data.videos);

                        const folders = [...new Set(
                            response.data.videos.map((v) => {
                                const split = v.video.split("/");
                                const folder = `${split.slice(0, -1).join('/')}/`;
                                return folder;
                            })
                        )];

                        resolve(folders);
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        getThumbnails(){
            return new Promise((resolve, reject) => {
                axios.get(`${ILLI_API_SERVER_URL}/folderThumbnails`)
                    .then(response => {
                        resolve(response.data.thumbnails);
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    });
            });
        },

        // Create table

        setHeaders(){
            this.table.headers = [
                {text: 'Map', value: 'folder'},
                {text: 'Thumbnail', value: 'thumbnail'},
                {text: '', value: 'button', filterable: false, sortable: false},
            ]
        },

        setRows(){
            this.table.rows = this.folders.map((f) => {
                return {folder: f.path, thumbnail: f.thumbnail, button: {path: f.path}};
            });
        },

        // Modal actions

        openEditModal(path){
            this.modal.edit.path = path;
            this.modal.edit.form.thumbnail.delete = false; 

            // Get data
            const folder = this.folders.find((f) => f.path == path);
            
            // Set modal data
            this.modal.edit.form.thumbnail.file = (folder?.thumbnail) ? `${THUMBNAIL_URL.folder}${folder.thumbnail.id}.${folder.thumbnail.extension}` : null;
            this.modal.edit.form.thumbnail.display = true;
            
            // Open modal
            this.modal.edit.display = true;
        },

        deleteThumbnail(){
            this.modal.edit.form.thumbnail.file = null;
            this.modal.edit.form.thumbnail.delete = true;
        },

        submitEdit(){
            console.log('edit folder');

            const folder = this.folders.find(f => f.path == this.modal.edit.path);
            const promises = [];

            // Upload file
            if (this.modal.edit.form.thumbnail.uploadFile){
                // Gather data
                const formData = new FormData();
                formData.append(this.modal.edit.path, this.modal.edit.form.thumbnail.uploadFile);

                // Add promise
                promises.push(axios.patch(`${ILLI_API_SERVER_URL}/folderThumbnail`, formData));
            }

            // Delete file
            if (this.modal.edit.form.thumbnail.delete){
                // Add promise
                promises.push(axios.delete(`${ILLI_API_SERVER_URL}/folderThumbnail/${folder.thumbnail.id}`));
            }

            // Execute promises
            Promise.all(promises)
                .then((response) => {
                    console.log({response});
                    return emitFeedback(this, 'Map succesvol aangepast', 'success'); // todo translate
                })
                .catch((err) => {
                    console.error({err});
                    return emitFeedback(this, 'Er is iets misgegaan', 'error'); // todo translate 
                })
                .finally(() => {
                    this.modal.edit.display = false;
                    EventBus.$emit('refresh');
                });

        },


        handleFileSelect(event){
            const file = event.target.files[0];
            this.modal.edit.form.thumbnail.delete = false;
            this.modal.edit.form.thumbnail.uploadFile = file;
            this.modal.edit.form.thumbnail.file = URL.createObjectURL(file);
        },

        getFilterOptions(){
            this.$emit("filterOptions", [])
        },

        getSelectedRows(){
            return this.table.selectedRows.length || 0;
        },

        // Include capitalize function here to use in template
        capitalize,
    },
}
</script>

<style lang="scss">

.folderTable{

    th{
        text-wrap: nowrap;
    }
    
    .thumbnailIcon{
        font-size: 15px!important;
    }

    td:last-child{
        position: sticky;
        right: 0;
    }

}

</style>

<style lang="scss" scoped>

.thumbnailContainer{
    position: relative;

    .buttons{
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        gap: 10px;
        padding: 10px;

        &.noThumbnail{
            padding: 0;
            position: relative
        }
    }

    img {
        width: 100%;
        display: block;
    } 
}

</style>