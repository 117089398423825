<template>
    <div>

        <v-dialog v-model="display" width="800" persistent>
            <v-card>
                <v-card-title>{{ title }}</v-card-title>

                <div class="modalBody" :class="{ 'disabled': uploading }">
                    
                    <VueFileAgent 
                        v-model="fileUpload"
                        :multiple="true"
                        :deletable="true"
                        :editable="true"
                        :meta="true"
                        :accept="acceptFormat"
                        :maxSize="'100GB'"
                        :helpText="$t('database.click_here_to_upload')"
                        :errorText="{type: 'Invalid file type.'}"
                        ref="VueFileUpload"
                        @beforedelete="onBeforeDelete($event)"
                        @rename="onRename($event)"
                    ></VueFileAgent>

                    <v-checkbox v-model="editAll" :label="$t(`tagSystem.modal.items.add.editAll`)"></v-checkbox> 

                    <table v-if="fileUpload.length">
                        <thead>
                            <tr>
                                <th v-if="slug == 'image'">{{ $t(`tagSystem.modal.items.add.table.image`) }}</th>
                                <th>{{ $t(`tagSystem.modal.items.add.table.location`) }}</th>
                                <th>{{ $t(`tagSystem.modal.items.add.table.name`) }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(file, index) in fileUpload" :key="index">
                                <td v-if="slug == 'image'">
                                    <div class="image">
                                        <img :src="file.urlResized" :alt="file.file.name">
                                    </div>
                                </td>
                                <td>
                                    <v-checkbox v-model="file.useCustomLocation" :label="$t(`tagSystem.modal.items.add.table.useCustomLocation`)"></v-checkbox>
                                    <v-text-field v-if="file.useCustomLocation" v-model="file.customLocation" @keyup="validateLocation(file)" />
                                    <v-autocomplete v-else :items="locationOptions" v-model="file.location" @change="onChangeLocation" />
                                </td>
                                <td>
                                    {{ file.file.name }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="uploading" class="upload-overlay"></div>
                </div>

                <v-divider></v-divider>
                <v-progress-linear v-if="uploading" :value="uploadProgress" color="primary"></v-progress-linear>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cancelUpload">
                        {{ $t(`tagSystem.modal.items.add.cancel.${slug}`) }}
                    </v-btn>
                    <v-btn :disabled="fileUpload.length === 0 || uploading" @click="save" color="primary">
                        {{ $t(`tagSystem.modal.items.add.save.${slug}`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Upload Complete -->
        <v-snackbar v-model="feedback.active" :timeout="3000" :color="feedback.color">
            <strong>{{ feedback.message }}</strong>
        </v-snackbar>
    </div>

</template>

<script>
import axios from 'axios'
import { capitalize } from '@/utils/utils.js';
import { EventBus } from '@/utils/eventBus';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import { Upload } from 'tus-js-client';

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        apiUrl: {
            type: String,
            default: ''
        },
        locationPrefix: {
            type: String,
            default: ''
        },
        acceptFormat: {
            type: String,
            default: '*'
        },
        locationOptions: {
            type: Array,
            default: () => []
        },
        slug: {
            type: String,
            default: '',
        },
        tus: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            FileBrowserVueFileAgentStyles: VueFileAgentStyles,
            display: false,
            fileUpload: [],
            locations: [],
            editAll: true,
            uploading: false,
            uploadProgress: 0,
            shouldCancelUpload: false,
            feedback: {
                active: false,
                message: '',
                color: ''
            }
        }
    },
    methods: {

        openModal(){
            // reset all values
            this.display = true;
            this.fileUpload= [];
            this.locations= [];
            this.uploading= false;
            this.uploadProgress= 0;
            this.shouldCancelUpload= false;
            this.feedback= {
                active: false,
                message: '',
                color: ''
            };
        },

        save() {
            this.uploading = true;
            if (this.tus) {
                const data = this.fileUpload.map(upload => {
                    const file = upload.file;
                    const location = `${this.locationPrefix}/${upload.location || ''}`;
                    return {file, location};
                });
                const bearerToken = axios.defaults.headers.common['Authorization'];
                // Use tus-js-client for resumable uploads (will make it actually resumable once we move to node 20)
                data.forEach(upload => {
                    const tusUpload = new Upload(upload.file, {
                        endpoint: this.apiUrl,
                        retryDelays: null, // Disable retries
                        chunkSize: 100 * 1024 * 1024, // Set the chunk size to 100MB
                        metadata: {
                            filename: upload.file.name,
                            filetype: upload.file.type,
                            location: upload.location
                        },
                        headers: {
                            Authorization: bearerToken,
                        },
                        onError: error => {
                            if (tusUpload.bytesUploaded === tusUpload.bytesTotal) {
                                // The upload has reached 100%, show the success flow
                                console.log(`Uploaded ${upload.file.name} to ${this.apiUrl}`);
                                this.uploading = false;
                                this.feedback = {
                                    active: true,
                                    message: 'Upload completed',
                                    color: 'success'
                                };
                                setTimeout(() => {
                                    this.display = false;
                                    EventBus.$emit('refresh');
                                }, 3100);
                            } else {
                                // The upload has not reached 100%, show the error message
                                console.log("Failed because: " + error)
                                this.uploading = false;
                                this.feedback = {
                                    active: true,
                                    message: 'Upload failed please try again',
                                    color: 'error'
                                };
                            }
                        },
                        onProgress: (bytesUploaded, bytesTotal) => {
                            const progress = (bytesUploaded / bytesTotal * 100).toFixed(2)
                            console.log(bytesUploaded, bytesTotal, progress + "%")
                            this.uploadProgress = progress;

                            // Cancel the upload if the cancelUpload flag is true
                            if (this.shouldCancelUpload) {
                                tusUpload.abort();
                            }
                        },
                        onSuccess: () => {
                            console.log(`Uploaded ${upload.file.name} to ${this.apiUrl}`);
                            this.uploading = false;
                            this.feedback = {
                                active: true,
                                message: 'Upload completed',
                                color: 'success'
                            };
                            setTimeout(() => {
                                this.display = false;
                                EventBus.$emit('refresh');
                            }, 3100);
                        }
                    })

                    tusUpload.start();
                });
            } else {
                // Use axios for non-resumable uploads
                for (let file of this.fileUpload) {
                    if (!file.location) {
                        file.location = '';
                    }
                }

                let formData = new FormData();

                const data = this.fileUpload.map(upload => {
                    const file = upload.file;
                    const location = `${this.locationPrefix}/${upload.location}`;
                    return {file, location};
                });
                
                data.forEach(upload => {
                    formData.append(upload.location, upload.file);
                });

                // Create a new CancelToken
                const CancelToken = axios.CancelToken;
                this.source = CancelToken.source();

                axios.post(this.apiUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: progressEvent => {
                        this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    },
                    cancelToken: this.source.token 
                    })
                    .then(() => {
                        this.uploading = false;
                        this.feedback = {
                            active: true,
                            message: 'Upload completed',
                            color: 'success'
                        };
                        setTimeout(() => {
                            this.display = false;
                            EventBus.$emit('refresh');
                        }, 3100);
                    })
                    .catch((err) => {
                    this.uploading = false;
                    if (axios.isCancel(err)) {
                        this.feedback = {
                            active: true,
                            message: 'Upload cancelled by the user',
                            color: 'warning'
                        };
                    } else {
                        this.feedback = {
                            active: true,
                            message: 'Upload failed please try again',
                            color: 'error'
                        };
                    }
                    console.error({err});
                })
            }
        },
        cancelUpload() {
            if (this.source) {
                this.source.cancel('Upload cancelled by the user');
            }
            // Set the cancelUpload flag to true
            this.shouldCancelUpload = true;
            this.display = false;
        },

        onChangeLocation(location){
            if (this.editAll){
                this.fileUpload = this.fileUpload.map((e) => ({...e, location: location}));
            }
        },

        onFileSelected(files) {
            this.fileUpload = files.map(file => ({ ...file, useCustomLocation: false, customLocation: '' }));
        },

        validateLocation(file) {
            file.customLocation = file.customLocation.replace(/\\/g, '/');
            file.location = file.customLocation;
        },

        // VueFileAgent

        onBeforeDelete: function (fileRecord) {
            const index = this.fileUpload.indexOf(fileRecord); 
            this.fileUpload.splice(index, 1);
        },

        
        onRename: function (fileRecord) {
            const currentFile = this.fileUpload.find(p => p.id == fileRecord.id).file;
            const newFile = new File([currentFile], fileRecord.name(), { type: currentFile.type });
            this.fileUpload.find(p => p.id == fileRecord.id).file = newFile;
        },
        capitalize
    }
}
</script>

<style lang="scss" scoped>
.disabled {
    position: relative;
}
.upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1000;
}
table{
    width: 100%;

    th{
        text-align: left;
    }
    td{
        .image{
            width: 200px;
            height: 100px;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}


</style>
