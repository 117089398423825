import { delay } from '@/utils/utils.js';

export function emitFeedback(component, message, color, duration = 1500){
    return new Promise((resolve) => {
        component.$emit("feedback", {message, color, duration});  
        delay(duration).then(() => resolve());
    });
}

// Get tags of item by category (ordered by category or id | get tag object or tag string)
export function getTagsByCategory(categoryTags, item, orderBy = 'category', getTagObject = false){
    const categories = categoryTags.reduce((obj, cur) => ({...obj, [cur[orderBy]]: []}), {})
    for (const tag of item.tags) {
        const returnValue = (getTagObject) ? {value: tag.id, text: tag.tag} : tag.tag;
        categories[tag.category[orderBy]].push(returnValue);
    }
    return categories;
}

export function filterRows(rows, filters, searchQuery){
    return rows.filter(row => {
        for (const filter of filters) {
            const columnValues = row[filter.column.value]?.map(e => e.value).filter(e => e !== undefined) || [];

            // If column has no values and empty is not selected
            // Or if column has values and selected filters do not contain any of the values
            // > return false (filter out row)
            if (
                (columnValues.length === 0 && !filter.selected.includes('*EMPTY*'))
                ||
                (columnValues.length > 0 && !filter.selected.some(selected => columnValues.includes(selected)))
            ) {
                return false;
            }
        }

        const rowString =  Object.values(row).map((val) => {
            if (Array.isArray(val)) {
                val = val.map((e) => e.text).join(' ');
            }
            else if (val && typeof val == 'object') {
                val = Object.values(val).join(' ');
            }
            return val;
        }).join(' ');

        // If row does not contain searchQuery, return false (filter out row)
        if (searchQuery) return rowString.toLowerCase().includes(searchQuery.toLowerCase());
        return true;
    });
}

export function displayName(path){
    let result = path.split('.');
    result.pop();
    result = result.join('').split('/').pop();
    result = result.replace(/^\d+-(?!_-)/, '');
    result = result.replaceAll('-_-', '*');
    result = result.replaceAll('-', ' ');
    result = result.replaceAll('*', ' - ');
    result = result.trim();
    if (result == '') console.warn('cannot format path:', path);
    return (result != '') ? result : path;
}

export function getLocationOptions(data){
    const uniquePaths = new Set();

    data.forEach(item => {
        const directories = item.path.split('/');
        directories.shift(); // Remove first folder
        directories.pop(); // Remove filename
        const path = directories.join('/');
        uniquePaths.add(path);
    });

    return [...uniquePaths];
}

export function getVideoLocationOptions(data){
    const uniquePaths = new Set();

    data.forEach(item => {
        const directories = item.video.split('/');
        directories.pop(); // Remove filename
        const path = directories.join('/');
        uniquePaths.add(path);
    });

    return [...uniquePaths];
}

export function sortOnText(a, b){
    return `${a[0].text}`.localeCompare(`${b[0].text}`);
}

export function sortOnIntValue(a, b){
    return a[0].value - b[0].value;
}

export function sortOnDisplay(a, b){
    return `${a.display}`.localeCompare(`${b.display}`);
}

export function sortOnTags(a, b){
    // Find the lowest text value in a
    const lowestA = a && a.length > 0 ? a.reduce((lowest, item) => item && item.text < lowest ? item.text : lowest, a[0].text) : undefined;

    // Find the lowest text value in b
    const lowestB = b && b.length > 0 ? b.reduce((lowest, item) => item && item.text < lowest ? item.text : lowest, b[0].text) : undefined;

    // Compare the lowest text values
    if (lowestA && lowestB) {
        if (lowestA < lowestB) {
            return -1;
        } else if (lowestA > lowestB) {
            return 1;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}   