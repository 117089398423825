<template>
    <div>

        <v-dialog v-model="display" width="500">
            <v-card>
                <v-card-title>{{ title }}</v-card-title>

                <div class="modalBody">
                    
                    <!-- Amount selected -->
                    <v-text-field
                        :value="`${selectedIds.length} ${$t('tagSystem.modal.selected')}`"
                        readonly
                        disabled
                    ></v-text-field>

                </div>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="display = false">
                        {{ $t(`tagSystem.modal.items.delete.cancel.${slug}`) }}
                    </v-btn>
                    <v-btn @click="save" color="danger">
                        {{ $t(`tagSystem.modal.items.delete.save.${slug}`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from 'axios'
import { capitalize } from '@/utils/utils.js';
import { EventBus } from '@/utils/eventBus';

export default {

    props: {
        title: {
            type: String,
            default: ''
        },
        selectedIds: {
            type: Array,
            default: () => []
        },
        apiUrl: {
            type: String,
            default: ''
        },
        slug: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            display: false,
        }
    },
    methods: {

        openModal(){
            this.display = true;
        },

        save(){

            Promise.all(
                this.selectedIds.map(id => this.deleteItem(id))
            )
            .then(() => {
                EventBus.$emit('refresh');
            })
            .catch((err) => {
                console.log({err});
            })

        },

        deleteItem(id){
            return new Promise((resolve, reject) => {

                const apiURL = this.apiUrl.replace('*_id_*', id);

                axios.delete(apiURL)
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

            });
        },

        capitalize
    }

}
</script>

<style scoped>



</style>
