<template>
    <div>

        <!-- Table -->

        <v-data-table
            v-model="table.selectedRows"
            :headers="table.headers"
            :items="filteredItems"
            :items-per-page.sync="perPage"
            :footer-props="{ itemsPerPageOptions: perPageOptions }"
            item-key="path"
            show-select
            dense
            :loading="loading"
            class="imageTable"
            :page.sync="page"
        >
        
            <template v-for="column in table.headers" v-slot:[`item.${column.value}`]="{ item }">
                {{ Array.from(item[column.value]).map(e => e.text).join(', ') }}
            </template>

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.image="{ item }">
                <img :src="item.image.full" class="tableImage">
            </template>
            
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.path="{ item }">
                {{ item.path }}
            </template>

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.dropdown="{ item }">

                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" small>
                            {{ $t(`tagSystem.tab.image.dropdown.manage`) }} <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item v-for="dropdownItem in item.dropdown" :key="dropdownItem.id" @click="dropdownItem.action">
                            <v-list-item-title>{{ dropdownItem.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

            </template>
            
        </v-data-table>

        <!-- Dialogs -->
        
        <v-dialog v-model="modal.edit.display" width="500">
            <v-card>
                <v-card-title>
                    {{ $t(`tagSystem.tab.image.modal.edit.title`) }}
                </v-card-title>
                
                <div class="modalBody">

                    <!-- Image -->
                    <h4>{{ $t(`tagSystem.tab.image.modal.edit.header.image`) }}</h4>

                    <v-text-field
                        v-model="modal.edit.form.image.location"
                        readonly
                        disabled
                    ></v-text-field>

                    <div class="imageContainer">
                        <img v-if="modal.edit.form.image.file" :src="modal.edit.form.image.file">
                    </div>

                    <!-- Image tags -->
                    <h4>{{ $t(`tagSystem.tab.image.modal.edit.header.tags`) }}</h4>

                    <v-combobox 
                        v-for="category in categoryTags" 
                        v-model="modal.edit.form.tags[category.id]"
                        :key="category.id"
                        :label="capitalize(category.category)"
                        :items="category.tags.map(t => ({text: t.tag, value: t.id}))"
                        item-text="text"
                        item-value="value"
                        multiple
                        clearable                        
                    ></v-combobox>

                </div> 

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="modal.edit.display = false">
                        {{ $t("tagSystem.tab.image.modal.edit.cancel") }}
                    </v-btn>
                    <v-btn @click="submitEdit" color="primary">
                        {{ $t(`tagSystem.tab.image.modal.edit.submit`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modal.delete.display" width="500">
            <v-card>
                <v-card-title>
                    {{ $t(`tagSystem.tab.image.modal.delete.title`) }}
                </v-card-title>
                
                <v-card-text>
                    {{ $t(`tagSystem.tab.image.modal.delete.description`) }}
                </v-card-text>
                
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="modal.delete.display = false">
                        {{ $t("tagSystem.tab.image.modal.delete.cancel") }}
                    </v-btn>
                    <v-btn @click="submitDelete" color="danger">
                        {{ $t(`tagSystem.tab.image.modal.delete.submit`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ModalTagAddToItem 
            :title="$t(`tagSystem.modal.tags.add.title`)" 
            :categoryTags="categoryTags"
            :selectedIds="table.selectedRows.map(e => e.image.id)"
            :apiUrl="`${apiServer}/images/*_id_*/addTags`"
            ref="modalTagAddToItem"
        />

        <ModalTagDeleteFromItem 
            :title="$t(`tagSystem.modal.tags.delete.title`)" 
            :selectedIds="table.selectedRows.map(e => e.image.id)"
            :apiUrl="`${apiServer}/images/*_id_*/removeTags`"
            ref="modalTagDeleteFromItem"
            :itemStr="$t(`tagSystem.tab.image.item`)"
            :tagOptions="selectedImagesTags"
        />

        <ModalItemDelete 
            :title="$t(`tagSystem.modal.items.delete.title.image`)" 
            :selectedIds="table.selectedRows.map(e => e.image.id)"
            :apiUrl="`${apiServer}/images/*_id_*`"
            slug="image"
            ref="modalItemDelete"
        />

        <ModalItemAddAndUpload 
            :title="$t(`tagSystem.modal.items.add.title.image`)" 
            :apiUrl="`${apiServer}/images/upload`"
            :locationOptions="locationOptions"
            locationPrefix="dagprogramma"
            acceptFormat=".png,.jpg,.jpeg,.webp"
            slug="image"
            ref="modalItemAdd"
        />

    </div>
</template>

<script>
import axios from "axios";
import { capitalize } from '@/utils/utils';
import { emitFeedback, getTagsByCategory, filterRows, getLocationOptions, sortOnTags } from '@/utils/tagSystem'; 
import ModalTagAddToItem from '@/components/tags/modals/TagAddToItem.vue';
import ModalTagDeleteFromItem from '@/components/tags/modals/TagDeleteFromItem.vue';
import ModalItemDelete from '@/components/tags/modals/ItemDelete.vue';
import ModalItemAddAndUpload from '@/components/tags/modals/ItemAddAndUpload.vue';
import { ILLI_API_SERVER_URL, ILLI_STREAM_STATIC_URL } from "../../config";
import { EventBus } from '@/utils/eventBus';
import tagTableMixin from '@/mixins/tagTableMixin';

export default {
    name: "ImageTable",
    components: {
        ModalTagAddToItem,
        ModalTagDeleteFromItem,
        ModalItemDelete,
        ModalItemAddAndUpload
    },
    mixins: [tagTableMixin],
    props: {
        searchQuery: {
            type: String,
            default: ''
        },
        filters: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        filteredItems() {
            return filterRows(this.table.rows, this.filters, this.searchQuery);
        },
        apiServer() {
            return ILLI_API_SERVER_URL;
        },
        selectedImagesTags(){
            const imageIds = this.table.selectedRows.map(e => e.image.id);

            return this.images
                .filter(e => imageIds.includes(e.id))
                .map(e => e.tags)
                .reduce((all, crr) => {
                    crr.forEach(obj => {
                        if (!all.some(allObj => allObj.id === obj.id)) {
                            all.push(obj);
                        }
                    });
                    return all;
                }, [])
                .sort((a, b) => a.tag.localeCompare(b.tag));
        }
    },
    watch: {
        'table.selectedRows': {
            handler(value) {
                this.$emit("selectedRows", {amount:  value.length});  
            },
        },
    },
    created(){

        Promise.all([
            this.getImages(),
            this.getCategoryTags(),
        ])
        .then(([imageData, categoryTagData]) => {

            // Set data
            this.images = imageData;
            this.categoryTags = categoryTagData.map(c => ({...c, tags: c.tags.sort((a, b) => a.tag.localeCompare(b.tag))}));
            this.locationOptions = getLocationOptions(this.images);

            // Create table
            this.setHeaders();
            this.setRows();

            // Emit filter options
            this.getFilterOptions();

        })
        .catch((err) => {
            console.error(err);
        })

    },
    data(){
        return {
            table: {
                headers: [],
                rows: [],
                selectedRows: [],
            },

            modal: {
                edit: {
                    imageId: null,
                    display: false,
                    form: {
                        image: {
                            file: '',
                            location: '',
                        },
                        tags: [],
                    }
                },
                delete: {
                    imageId: null,
                    display: false,
                }
            },

            images: [],
            categoryTags: [],
            locationOptions: [],
        }
    },
    methods: {
        // Get data from API

        getImages(){
            return new Promise((resolve, reject) => {
                axios.get(`${ILLI_API_SERVER_URL}/images`)
                    .then(response => {
                        resolve(response.data.imagesTags);
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        getCategoryTags(){
            return new Promise((resolve, reject) => {
                axios.get(`${ILLI_API_SERVER_URL}/categoryTags`)
                    .then(response => {
                        resolve(response.data.categoryTags);
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    });
            });
        },

        // Create table

        setHeaders(){
            const headerImage = {text: 'Afbeelding', value: 'image', filterable: false, sortable: false};
            const headerLocation = {text: 'Locatie', value: 'path'};
            const headerDropdown = {text: '', value: 'dropdown', filterable: false, sortable: false};
            this.table.headers = [headerImage, headerLocation]
                .concat(this.categoryTags.map((e) => ({text: capitalize(e.category), value: e.category, sort: sortOnTags})))
                .concat([headerDropdown]);
        },

        setRows(){
            this.table.rows = this.images.map((i) => {
                const tagsByCategory = getTagsByCategory(this.categoryTags, i, 'category', true);

                return {image: {id: i.id, full: `${ILLI_STREAM_STATIC_URL}/${i.path}`}, path: i.path, ...tagsByCategory, dropdown: this.getDropdown(i.id)};
            });
        },

        getDropdown(id){
            return [
                {id: 'edit', text: this.$t(`tagSystem.tab.image.dropdown.edit`), action: () => this.openEditModal(id)},
                {id: 'delete', text: this.$t(`tagSystem.tab.image.dropdown.delete`), action: () => this.openDeleteModal(id)},
            ];
        },

        // Modal actions

        openEditModal(id){
            this.modal.edit.imageId = id;

            // Get data
            const image = this.images.find(v => v.id == id);
            const tagsByCategory = getTagsByCategory(this.categoryTags, image, 'id', true);

            console.warn({image});

            // Set modal data
            this.modal.edit.form.image.location = image.path;
            this.modal.edit.form.image.file = `${ILLI_STREAM_STATIC_URL}/${image.path}`;
            this.modal.edit.form.tags = tagsByCategory;
            
            // Open modal
            this.modal.edit.display = true;
        },

        submitEdit(){
            console.log('edit image', this.modal.edit.imageId);

            const body = {
                tags: Object.values(this.modal.edit.form.tags).flatMap(t => t).map(t => t.value),
            };

            axios.patch(`${ILLI_API_SERVER_URL}/images/${this.modal.edit.imageId}`, body)
                .then((response) => {
                    console.log({response});
                    return emitFeedback(this, 'Afbeelding succesvol aangepast', 'success'); // todo translate
                })
                .catch((error) => {
                    console.error({error});
                    return emitFeedback(this, 'Er is iets misgegaan', 'error'); // todo translate 
                })
                .finally(() => {
                    this.modal.edit.display = false;
                    EventBus.$emit('refresh');
                });

        },

        openDeleteModal(id){
            this.modal.delete.imageId = id;

            // Open modal
            this.modal.delete.display = true;
        },

        submitDelete(){
            console.log('delete image', this.modal.delete.imageId);

            axios.delete(`${ILLI_API_SERVER_URL}/images/${this.modal.delete.imageId}`)
                .then((response) => {
                    console.log({response});
                    return emitFeedback(this, 'Afbeelding succesvol verwijderd', 'success'); // todo translate
                })
                .catch((error) => {
                    console.error({error});
                    return emitFeedback(this, 'Er is iets misgegaan', 'error'); // todo translate 
                })
                .finally(() => {
                    this.modal.delete.display = false;
                    EventBus.$emit('refresh');
                });
        },

        openAddTagToItemModal(){
            this.$refs.modalTagAddToItem.openModal();
        },

        openDeleteTagFromItemModal(){
            this.$refs.modalTagDeleteFromItem.openModal();
        },

        openDeleteItemModal(){
            this.$refs.modalItemDelete.openModal();
        },

        openAddItemModal(){
            this.$refs.modalItemAdd.openModal();
        },

        // Functions

        getFilterOptions(){
            const filters = this.categoryTags.map((e) => ({
                column: {
                    name: e.category,
                    value: e.category,
                },
                options: e.tags.map((t) => ({text: t.tag, value: t.id})),
                selected: e.tags.map((t) => t.id),
            }));

            this.$emit("filterOptions", filters)
        },

        getSelectedRows(){
            return this.table.selectedRows.length || 0;
        },

        // Include capitalize function here to use in template
        capitalize,
    },
}
</script>

<style lang="scss">

.imageTable{

    th{
        text-wrap: nowrap;
    }

    td:last-child{
        position: sticky;
        right: 0;
    }
    
}

</style>

<style lang="scss" scoped>

.tableImage{
    width: 150px;
}

.imageContainer img {
    width: 100%;
}

</style>