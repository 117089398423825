<template>
    <div>

        <v-dialog v-model="display" width="500">
            <v-card>
                <v-card-title>{{ title }}</v-card-title>

                <div class="modalBody">
                    
                    <!-- Amount selected -->
                    <v-text-field
                        :value="`${selectedIds.length} ${itemStr} ${$t('tagSystem.modal.selected')}`.trim()"
                        readonly
                        disabled
                    ></v-text-field>

                    <!-- Tags -->
                    <div v-if="tagOptions.length">
                        <v-chip
                            class="ma-1"
                            color="black"
                            dark
                            @click="toggleAllTags()"
                        >
                            {{ $t('tagSystem.modal.selectAll') }}
                        </v-chip>

                        <v-chip
                            v-for="(tag, index) in tagOptions"
                            :key="index"
                            class="ma-1"
                            :color="tag.selected ? 'primary' : ''"
                            :value="tag.id"
                            @click="clickTag(tag.id)"
                        >
                            {{ tag.tag }}
                        </v-chip>
                    </div>
                </div>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="display = false">
                        {{ $t("tagSystem.modal.tags.delete.cancel") }}
                    </v-btn>
                    <v-btn 
                        @click="save" 
                        color="danger" 
                        :disabled="saveButtonDisabled"
                    >
                        {{ $t(`tagSystem.modal.tags.delete.save`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from 'axios'
import { capitalize } from '@/utils/utils.js';
import { EventBus } from '@/utils/eventBus';

export default {

    props: {
        title: {
            type: String,
            default: ''
        },
        selectedIds: {
            type: Array,
            default: () => []
        },
        apiUrl: {
            type: String,
            default: ''
        },
        tagOptions: {
            type: Array,
            default: () => []
        },
        itemStr: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            display: false
        }
    },
    computed: {
        saveButtonDisabled(){
            return this.tagOptions.length > 0 && !this.tagOptions.filter(e => e.selected).length > 0;
        }
    },
    methods: {

        openModal(){
            this.display = true;
        },

        save(){
            const selectedTags = this.tagOptions.filter(t => t.selected).map(t => t.id);

            Promise.all(
                this.selectedIds.map(id => this.deleteTags(id, selectedTags))
            )
            .then(() => {
                EventBus.$emit('refresh');
            })
            .catch((err) => {
                console.log({err});
            })
        },

        deleteTags(id, selectedTags){
            return new Promise((resolve, reject) => {

                const apiURL = this.apiUrl.replace('*_id_*', id);

                axios.patch(apiURL, {tags: selectedTags})
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

            });
        },

        clickTag(tagId){
            this.tagOptions
                .filter(t => t.id == tagId)
                .forEach(t => this.$set(t, 'selected', !t.selected));
        },

        toggleAllTags(){
            const selected = this.tagOptions.filter(t => t.selected).length;
            const total = this.tagOptions.length;

            if(selected == total){
                this.tagOptions.forEach(t => this.$set(t, 'selected', false));
            } else {
                this.tagOptions.forEach(t => this.$set(t, 'selected', true));
            }
        },

        capitalize
    }

}
</script>

<style scoped>



</style>
