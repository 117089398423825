<template>
    <v-card class="pa-3">
        <!-- Top bar -->
        <div class="topBar">

            <!-- Search bar -->
            <v-text-field @keyup="handleSearchKeyup" @clear="handleSearchClear" :placeholder="$t('tagSystem.search')" append-icon="mdi-magnify" clearable outlined dense></v-text-field>

            <!-- Filter bar -->
            <v-menu offset-y content-class="filterBar">

                <!-- Filter bar button -->
                <template v-slot:activator="{ on: openBar, attrs: barAttrs }">
                    <v-btn v-bind="barAttrs" v-on="openBar" small depressed outlined :color="filters.every((filter) => filter.selected.length == filter.options.length) ? '' : 'primary'">
                        <v-icon>mdi-filter</v-icon> {{ $t('tagSystem.filter.button') }}
                    </v-btn>
                </template>

                <!-- Filter bar horizontal menu -->
                <v-list dense>
                    <v-list-item-group active-class="active" class="filterListItemGroup">
                        <v-list-item v-for="filter in filters" :key="filter.column.value">
                            <v-menu offset-x content-class="filterMenu" :close-on-content-click="false">

                                <!-- Filter bar column buttons -->
                                <template v-slot:activator="{ on: openList, attrs: listAttrs }">
                                    <v-btn v-bind="listAttrs" v-on="openList" small block depressed outlined :color="filter.selected.length == filter.options.length ? '' : 'primary'">
                                        {{filter.column.name}}
                                        <v-icon>mdi-menu-right</v-icon>
                                    </v-btn>
                                </template>

                                <!-- Filter bar column options menu -->
                                <v-list dense>
                                    <v-list-item-group active-class="active">

                                        <!-- All & Empty options -->
                                        <v-list-item v-for="option in filter.options.filter(e => e.location == 'top')" :key="option.value" @click="toggleFilter(filter, option.value)"> 
                                            <div class="filterItem">
                                                <v-list-item-action class="ma-0">
                                                    <v-simple-checkbox
                                                        :value="(option.value == '*ALL*') ? filter.selected.length == filter.options.length : filter.selected.includes(option.value)"
                                                        @click="toggleFilter(filter, option.value)"
                                                        @click.stop
                                                    ></v-simple-checkbox>
                                                </v-list-item-action>

                                                <v-list-item-content>
                                                    <v-list-item-title>{{option.text}}</v-list-item-title>
                                                </v-list-item-content>
                                            </div>
                                        </v-list-item>

                                        <hr>

                                        <!-- Other options -->
                                        <v-list-item v-for="option in filter.options.filter(e => e.location != 'top')" :key="option.value" @click="toggleFilter(filter, option.value)"> 
                                            <div class="filterItem">
                                                <v-list-item-action class="ma-0">
                                                    <v-simple-checkbox
                                                        :value="filter.selected.includes(option.value)"
                                                        @click="toggleFilter(filter, option.value)"
                                                        @click.stop
                                                    ></v-simple-checkbox>
                                                </v-list-item-action>

                                                <v-list-item-content>
                                                    <v-list-item-title>{{option.text}}</v-list-item-title>
                                                </v-list-item-content>
                                            </div>
                                        </v-list-item>

                                    </v-list-item-group>
                                </v-list>

                            </v-menu>
                        </v-list-item>
                        
                        <v-list-item>
                            <v-btn small block depressed outlined @click="resetFilters">
                                <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                        </v-list-item>

                    </v-list-item-group>
                </v-list>
            </v-menu>
            
            <!-- Add tags auto -->
            <v-tooltip bottom v-if="activeTabObject && activeTabObject.buttons.tags.auto">
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <v-btn @click="openAddTagAutoModal" small depressed outlined>
                            <v-icon>mdi-auto-fix</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span>{{ $t(`tagSystem.button.tags.auto`) }}</span> 
            </v-tooltip>

            <!-- Rows selected buttons -->
            <v-btn-toggle v-if="activeTabObject">
                <v-btn @click="openAddTagToItemModal" v-if="activeTabObject.buttons.tags.add" :disabled="!rowsSelected" small>{{ $t(`tagSystem.button.tags.add`) }}</v-btn>
                <v-btn @click="openDeleteTagFromItemModal" v-if="activeTabObject.buttons.tags.delete" :disabled="!rowsSelected" small>{{ $t(`tagSystem.button.tags.delete`) }}</v-btn>
                <v-btn @click="openDeleteItemModal" v-if="activeTabObject.buttons.delete" :disabled="!rowsSelected" small>{{ $t(`tagSystem.button.delete.${activeTab}`) }}</v-btn>
                <v-btn @click="openEditTagModal" v-if="activeTabObject.buttons.tag.edit" :disabled="!rowsSelected" small>{{ $t(`tagSystem.button.tag.edit`) }}</v-btn>
                <v-btn @click="openDeleteTagModal" v-if="activeTabObject.buttons.tag.delete" :disabled="!rowsSelected" small>{{ $t(`tagSystem.button.tag.delete`) }}</v-btn>
            </v-btn-toggle>

            <!-- Add item button -->
            <v-btn @click="openAddItemModal" v-if="activeTabObject && activeTabObject.buttons.add" color="primary" small>
                {{ $t(`tagSystem.button.add.${activeTab}`) }}
            </v-btn>
        </div>
        
        <!-- Tab navigation -->
        <v-tabs v-model="activeTab" bg-color="primary">
            <v-tab :key="tab.value" :href="'#' + tab.value" v-for="tab in tabs" @click="resetPageQuery">
                {{ $t(`tagSystem.tab.${tab.value}.title`) }}
            </v-tab>
        </v-tabs>

        <!-- Tab content -->
        <v-tabs-items v-model="activeTab">
            <v-tab-item :key="tab.value" :value="tab.value" v-for="tab in tabs">
                <v-card flat>
                    <!-- todo: remove v-if -->
                    <component 
                        v-if="tab.component" 
                        :is="tab.component"
                        :searchQuery="searchQuery" 
                        :filters="filters"
                        @feedback="showFeedback" 
                        @selectedRows="setSelectedRows"
                        @filterOptions="updateFilterBar"
                        :ref="`table-${tab.value}`"
                    ></component>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <!-- Feedback -->
        <v-snackbar v-model="feedback.display" :timeout="feedback.duration" :color="feedback.color">
            <strong>{{ feedback.message }}</strong>
        </v-snackbar>

    </v-card>
</template>

<script>
import VideoTable from '@/components/tags/VideoTable.vue';
import ImageTable from '@/components/tags/ImageTable.vue';
import SongTable from '@/components/tags/SongTable.vue';
import TagTable from '@/components/tags/TagTable.vue';
import FolderTable from '@/components/tags/FolderTable.vue';
            
export default {
    components: {
        VideoTable,
        ImageTable,
        SongTable,
        TagTable,
        FolderTable,
    },
    computed: {
        activeTabObject() {
            return this.tabs.find(t => t.value === this.activeTab);
        },
        activeTabRef() {
            return this.$refs[`table-${this.activeTab}`][0];
        }
    },
    watch: {
        activeTab(newTab) {
            this.$nextTick(this.handleActiveTabChange(newTab));
        },
    },
    created() {
        this.activeTab = this.$route.query.tab || 'video';
        this.searchQuery = this.$route.query.query || '';
        
        this.$nextTick(() => {
            this.isCreated = true
        });
    },
    data() {
        return {
            isCreated: false,

            searchQuery: '',
            searchTimeout: null,
            filters: [],

            activeTab: null,
            tabs: [
                { 
                    value: 'video', 
                    component: VideoTable, 
                    buttons: {
                        add: true,
                        tags: {
                            add: true,
                            delete: true,
                            auto: true,
                        },
                        tag: {
                            edit: false,
                            delete: false,
                        },
                        delete: true,
                    } 
                },
                { 
                    value: 'image', 
                    component: ImageTable, 
                    buttons: {
                        add: true, 
                        tags: {
                            add: true,
                            delete: true,
                            auto: false,
                        },
                        tag: {
                            edit: false,
                            delete: false,
                        },
                        delete: true,
                    } 
                },
                { 
                    value: 'song', 
                    component: SongTable, 
                    buttons: {
                        add: true, 
                        tags: {
                            add: true,
                            delete: true,
                            auto: false,
                        },
                        tag: {
                            edit: false,
                            delete: false,
                        },
                        delete: true,
                    } 
                },
                { 
                    value: 'tag', 
                    component: TagTable, 
                    buttons: {
                        add: true, 
                        tags: {
                            add: false,
                            delete: false,
                            auto: false,
                        },
                        tag: {
                            edit: true,
                            delete: true,
                        },
                        delete: false,
                    } 
                },
                { 
                    value: 'folder', 
                    component: FolderTable, 
                    buttons: {
                        add: false, 
                        tags: {
                            add: false,
                            delete: false,
                            auto: false,
                        },
                        tag: {
                            edit: false,
                            delete: false,
                        },
                        delete: false,
                    } 
                },
            ],

            rowsSelected: false,

            feedback: {
                display: false,
                duration: 1500,
                message: '',
                color: 'success'
            },
        }
    },
    methods: {

        showFeedback(feedback) {
            this.feedback.display = true;
            this.feedback.duration = feedback.duration;
            this.feedback.message = feedback.message;
            this.feedback.color = feedback.color;
        },
        
        setSelectedRows(selectedRows){
            this.rowsSelected = (selectedRows.amount > 0);
        },

        toggleFilter(filter, value){
            console.log({filter, value});

            if (value == '*ALL*'){

                // Toggle all
                if (filter.selected.length == filter.options.length){
                    filter.selected = [];
                } else {
                    filter.selected = filter.options.map(e => e.value);
                }

            } else {

                // Toggle filter
                const index = filter.selected.indexOf(value);
    
                if (index >= 0) filter.selected.splice(index, 1);
                else filter.selected.push(value);

            }

            this.removeTagQuery();
        },

        updateFilterBar(filterOptions){       
            const queryTag = (this.$route.query.tag) ? parseInt(this.$route.query.tag) : null;

            this.filters = filterOptions.map((filter) => {
                filter.options.unshift(
                    {value: '*ALL*', text: this.$t('tagSystem.filter.all'), location: 'top'},
                    {value: '*EMPTY*', text: this.$t('tagSystem.filter.empty'), location: 'top'}
                );
                filter.selected.unshift('*ALL*', '*EMPTY*');

                // If tag query, filter on tag
                if (queryTag && filter.selected.includes(queryTag)){
                    filter.selected = [queryTag];
                }

                return {
                    column: filter.column,
                    options: filter.options,
                    selected: filter.selected,
                }
            });
        },

        resetFilters(){
            this.filters = this.filters.map(filter => ({
                ...filter,
                selected: filter.options.map(e => e.value)
            }));

            this.removeTagQuery();
        },

        handleActiveTabChange(newTab){
            if (this.$refs[`table-${this.activeTab}`]) {
                this.activeTabRef.getFilterOptions();
                this.rowsSelected = this.activeTabRef.getSelectedRows();

                if (this.isCreated){ // prevent initial tab change
                    this.$router.push({ query: { ...this.$route.query, tab: newTab } }).catch(() => {});
                }
            } else {
                setTimeout(() => this.handleActiveTabChange(newTab), 100);
            }
        },

        removeTagQuery() {
            this.$router.push({ path: this.$route.path, query: { ...this.$route.query, tag: undefined } }).catch(() => {});
        },

        handleSearchClear(){
            this.$router.push({ query: { ...this.$route.query, query: undefined } }).catch(() => {});
        },

        handleSearchKeyup(event){
            const query = event.target.value;
            this.$router.push({ query: { ...this.$route.query, query } }).catch(() => {});

            if (this.searchTimeout) clearTimeout(this.searchTimeout);

            this.searchTimeout = setTimeout(() => {
                this.searchQuery = query;
            }, 500);
        },

        getTabHref(tabValue){
            // Create a copy of the current route's query
            let query = { ...this.$route.query };

            // Remove the query parameter you want to omit
            delete query.page;

            // Generate the new href
            let href = this.$router.resolve({ path: `#${tabValue}`, query: query }).href;

            return href;
        },

        resetPageQuery(){
            this.$router.push({ path: this.$route.path, query: { ...this.$route.query, page: undefined } }).catch(() => {});
        },

        // Modals

        openAddTagAutoModal(){
            this.activeTabRef.openAddTagAutoModal();
        },

        openAddTagToItemModal(){
            this.activeTabRef.openAddTagToItemModal();
        },

        openDeleteTagFromItemModal(){
            this.activeTabRef.openDeleteTagFromItemModal();
        },

        openAddItemModal(){
            this.activeTabRef.openAddItemModal();
        },

        openEditItemModal(){
            this.activeTabRef.openEditItemModal();
        },

        openDeleteItemModal(){
            this.activeTabRef.openDeleteItemModal();
        },

        openEditTagModal(){
            this.activeTabRef.openEditTagModal();
        },
        
        openDeleteTagModal(){
            this.activeTabRef.openDeleteTagModal();
        },

    }
}
</script>

<style lang="scss">

.filterBar {
    overflow: hidden!important;

    .v-list-item{
        min-height: auto!important;
        padding: 0;
    }

    &>.v-list{
        padding: 0;
    }

}

</style>

<style lang="scss" scoped>

.topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    gap: 20px;
}

.filterListItemGroup{
    > div {
        &:first-child > .v-btn{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child > .v-btn{
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        &:not(:first-child):not(:last-child) > .v-btn{
            border-radius: 0;
        }
    }
}

.filterMenu{
    max-height: 500px;
}

.filterItem{
    display: flex;
    cursor: pointer;
    gap: 10px;
}

</style>