var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"tagTable",attrs:{"headers":_vm.table.headers,"items":_vm.filteredItems,"items-per-page":_vm.perPage,"footer-props":{ itemsPerPageOptions: _vm.perPageOptions },"item-key":"key","show-select":"","dense":"","loading":_vm.loading,"page":_vm.page},on:{"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([_vm._l((_vm.table.headers),function(column){return {key:("item." + (column.value)),fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Array.from(item[column.value]).map(function (e) { return e.text; }).join(', '))+" ")]}}}),{key:"item.videoCount",fn:function(ref){
var item = ref.item;
return [(item.videoCount[0].value > 0)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.openVideosWithTag(item.id[0].value)}}},[_vm._v(" "+_vm._s(item.videoCount[0].text)+" ")]):_c('span',[_vm._v(_vm._s(item.videoCount[0].text))])]}},{key:"item.songCount",fn:function(ref){
var item = ref.item;
return [(item.songCount[0].value > 0)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.openSongsWithTag(item.id[0].value)}}},[_vm._v(" "+_vm._s(item.songCount[0].text)+" ")]):_c('span',[_vm._v(_vm._s(item.songCount[0].text))])]}},{key:"item.imageCount",fn:function(ref){
var item = ref.item;
return [(item.imageCount[0].value > 0)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.openImagesWithTag(item.id[0].value)}}},[_vm._v(" "+_vm._s(item.imageCount[0].text)+" ")]):_c('span',[_vm._v(_vm._s(item.imageCount[0].text))])]}},{key:"item.dropdown",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("tagSystem.tab.tag.dropdown.manage"))+" "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.dropdown),function(dropdownItem){return _c('v-list-item',{key:dropdownItem.id,on:{"click":dropdownItem.action}},[_c('v-list-item-title',[_vm._v(_vm._s(dropdownItem.text))])],1)}),1)],1)]}}],null,true),model:{value:(_vm.table.selectedRows),callback:function ($$v) {_vm.$set(_vm.table, "selectedRows", $$v)},expression:"table.selectedRows"}}),_c('ModalTagAddOrEdit',{ref:"modalTagAdd",attrs:{"slug":"add","categories":_vm.categories,"apiUrl":(_vm.apiServer + "/tags/")}}),_c('ModalTagAddOrEdit',{ref:"modalTagEdit",attrs:{"slug":"edit","categories":_vm.categories,"selected":_vm.selected,"apiUrl":(_vm.apiServer + "/tags/*_id_*")}}),_c('ModalItemDelete',{ref:"modalTagDelete",attrs:{"title":_vm.$t("tagSystem.modal.items.delete.title.tag"),"selectedIds":_vm.selected.ids,"apiUrl":(_vm.apiServer + "/tags/*_id_*"),"slug":"tag"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }