export default {
    data() {
        return {
            page: 1,
            perPage: 25,
            perPageOptions: [10, 25, 50, 100, 250, -1],
            loading: true,
        };
    },
    async mounted() {
                
        while (!this.table.headers.length > 0 || !this.table.rows.length > 0){
            await new Promise(resolve => setTimeout(resolve, 500));
        }

        this.$nextTick(() => {
            this.page = Number(this.$route.query.page) || 1;
            this.perPage = Number(this.$route.query.perPage) || 25;        
            this.loading = false;
        })    

    },
    watch: {
        page(newPage) {
            this.$router.push({ query: { ...this.$route.query, page: newPage } }).catch(() => { });
        },
        perPage(newPerPage) {
            this.$router.push({ query: { ...this.$route.query, perPage: newPerPage } }).catch(() => { });
        },
        '$route.query.page': function (newPage) {
            this.page = Number(newPage) || 1;
        },
        '$route.query.perPage': function (newPerPage) {
            this.perPage = Number(newPerPage) || 25;
        }
    }
};