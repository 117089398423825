<template>
    <div>

        <v-dialog v-model="display" width="500">
            <v-card>
                <v-card-title>{{ title }}</v-card-title>

                <div class="modalBody">
                    
                    <!-- Amount selected -->
                    <v-text-field
                        :value="`${selectedIds.length} ${$t('tagSystem.modal.selected')}`"
                        readonly
                        disabled
                    ></v-text-field>

                    <!-- Tags -->
                    <h4>{{ $t(`tagSystem.modal.tags.add.tags`) }}</h4>
                    
                    <v-combobox
                        v-for="category in categoryTags"
                        v-model="form.tags[category.id]"
                        :key="category.id"
                        :label="capitalize(category.category)"
                        :items="category.tags"
                        item-text="tag"
                        item-value="id"
                        multiple
                        clearable                        
                    ></v-combobox>

                </div>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="display = false">
                        {{ $t("tagSystem.modal.tags.add.cancel") }}
                    </v-btn>
                    <v-btn @click="save" color="primary">
                        {{ $t(`tagSystem.modal.tags.add.save`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from 'axios'
import { capitalize } from '@/utils/utils.js';
import { EventBus } from '@/utils/eventBus';

export default {

    props: {
        title: {
            type: String,
            default: ''
        },
        categoryTags: {
            type: Array,
            default: () => []
        },
        selectedIds: {
            type: Array,
            default: () => []
        },
        apiUrl: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            display: false,
            form: {
                tags: []
            }
        }
    },
    methods: {

        openModal(){
            this.display = true;
        },

        save(){

            const tags = Object.values(this.form.tags).flatMap(t => t).map(t => t.id);

            Promise.all(
                this.selectedIds.map(id => this.addTags(id, tags))
            )
            .then(() => {
                EventBus.$emit('refresh');
            })
            .catch((err) => {
                console.log({err});
            })


        },

        addTags(id, tags){
            return new Promise((resolve, reject) => {

                const apiURL = this.apiUrl.replace('*_id_*', id);

                axios.patch(apiURL, {tags})
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

            });
        },

        capitalize
    }

}
</script>

<style scoped>



</style>
