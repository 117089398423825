<template>
    <div>

        <v-dialog v-model="display" width="500">
            <v-card>
                <v-card-title>{{ $t(`tagSystem.modal.tag.${slug}.title`) }}</v-card-title>

                <div class="modalBody">
                    
                    <!-- Tag -->
                    <v-text-field
                        v-if="slug == 'add' || slug == 'edit' && selected.ids.length == 1"
                        v-model="form.name"
                        :label="$t(`tagSystem.modal.tag.${slug}.form.name`)"
                    ></v-text-field>

                    <!-- Amount selected -->
                    <v-text-field
                        v-if="slug == 'edit' && selected.ids.length > 1"
                        :value="`${selected.ids.length} ${$t('tagSystem.modal.selected')}`"
                        readonly
                        disabled
                    ></v-text-field>

                    <!-- Category -->
                    <v-autocomplete
                        v-model="form.category"
                        :label="$t(`tagSystem.modal.tag.${slug}.form.category`)"
                        :items="categories"
                        item-text="category"
                        item-value="id"
                        clearable                        
                    ></v-autocomplete>

                </div>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="display = false">
                        {{ $t(`tagSystem.modal.tag.${slug}.cancel`) }}
                    </v-btn>
                    <v-btn @click="save" color="primary">
                        {{ $t(`tagSystem.modal.tag.${slug}.save`) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from 'axios'
import { capitalize } from '@/utils/utils.js';
import { EventBus } from '@/utils/eventBus';

export default {

    props: {
        title: {
            type: String,
            default: ''
        },
        categories: {
            type: Array,
            default: () => []
        },
        apiUrl: {
            type: String,
            default: ''
        },
        slug: {
            type: String,
            default: ''
        },
        selected: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            display: false,
            form: {
                name: null,
                category: null,
            }
        }
    },
    created(){

    },
    methods: {

        openModal(){
            // Set data
            if (this.selected.ids?.length == 1){
                this.form.name = this.selected.tag;
                this.form.category = this.selected.category;
            } else {
                this.form.name = null;
                this.form.category = null;
            }

            this.display = true;
        },

        save(){

            
            switch (this.slug) {
                case 'add': {

                    const body = {tag: this.form.name, category: this.form.category};

                    axios.post(this.apiUrl, body)
                    .then(() => {
                        EventBus.$emit('refresh');
                    })
                    .catch((err) => {
                        console.error({err});
                    })


                }          
                break;

                case 'edit': {

                    const body = {
                        tag: (this.selected.ids.length == 1) 
                            ? this.form.name 
                            : null,
                        category: this.form.category
                    };

                    Promise.all(
                        this.selected.ids.map((id) => {
                            return new Promise((resolve, reject) => {
                                const apiURL = this.apiUrl.replace('*_id_*', id);
    
                                axios.patch(apiURL, body)
                                .then(() => {
                                    resolve();
                                })
                                .catch((err) => {
                                    reject(err);
                                }) 
                            });
                        })
                    )
                    .then(() => {
                        EventBus.$emit('refresh');
                    })
                    .catch((err) => {
                        console.error({err});
                    })

                }
                break;
            }


        },

        capitalize
    }

}
</script>

<style scoped>



</style>
